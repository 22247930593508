import React from 'react';
import closeIcon from "./icons/close.svg";
import leftArrowIcon from "./icons/left_arrow.svg";
import fullCircleIcon from "./icons/circle_full.svg";
import emptyCircleIcon from "./icons/circle_empty.svg";

class MultiImage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            imageIndex: 0
        }

    }

    nextImage = (e) => {

        e.stopPropagation();

        this.setState(prevState => {

            let newIndex;

            if (prevState.imageIndex + 1 === this.props.images.length) {
                newIndex = 0;
            } else {
                newIndex = prevState.imageIndex + 1
            }
            return { imageIndex: newIndex }

        })

    }

    previousImage = (e) => {

        e.stopPropagation();

        this.setState(prevState => {

            let newIndex;

            if (prevState.imageIndex === 0) {
                newIndex = this.props.images.length - 1;
            } else {
                newIndex = prevState.imageIndex - 1;
            }

            return { imageIndex: newIndex }

        })

    }

    render() {

        return <div
            className={"multi-image-container " + this.props.className}
            style={{ ...this.props.style }}
            onClick={this.nextImage}
        >

            {/* <div
                className="multi-image-image-wrapper"
            > */}

            <img
                alt=""
                className="multi-image-image"
                src={this.props.images[this.state.imageIndex]}

            />

            {/* </div> */}

            <div
                className="multi-image-controls-container"
            >
                <img
                    alt=""
                    src={leftArrowIcon}
                    className="multi-image-control-button prev"
                    onClick={this.previousImage}
                />

                <div
                    className="multi-image-controls-label"
                >{`${this.state.imageIndex + 1}/${this.props.images.length}`}</div>

                <img
                    alt=""
                    src={leftArrowIcon}
                    className="multi-image-control-button next"
                    onClick={this.nextImage}
                />
            </div>


        </div>

    }

}

export default MultiImage;