import React from 'react';
import closeIcon from "./icons/close.svg"

class Project extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        }

    }

    render() {

        return <>

            <div
                className={this.props.className}
                style={{ ...this.props.style }}
                onClick={() => this.setState((prevState) => { return { expanded: !prevState.expanded } })}
            >
                {this.props.previewContent}
            </div>

            {this.state.expanded ? <div
                className="project-container"

            >
                <img
                    className="close-button"
                    alt=""
                    src={closeIcon}
                    onClick={() => this.setState((prevState) => { return { expanded: !prevState.expanded } })}
                />
                {this.props.expandedContent}
            </div> : null}



        </>

    }

}

export default Project;