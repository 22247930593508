import React from 'react';
import Project from './Project';
import MultiImage from './MultiImage';
import androidIcon from "./icons/android.svg";
import htmlIcon from "./icons/html.svg";
import javascriptIcon from "./icons/javascript.svg";
import chipIcon from "./icons/chip.svg";

import pathPlannerImage1 from "./images/path_planner_1.png";
import pathPlannerImage2 from "./images/path_planner_2.png";
import destinyImage1 from "./images/destiny_2.png";
import destinyImage2 from "./images/destiny_1.png";
import destinyImage3 from "./images/destiny_3.png";
import wingsuitImage1 from "./images/wingsuit_2.png";
import wingsuitImage2 from "./images/wingsuit_1.png";
import tryppiImage1 from "./images/tryppi_1.png";
import tryppiImage2 from "./images/tryppi_2.png";

const solvableImage1 = "https://play-lh.googleusercontent.com/wG5xoJHgCD5NxjZ8RwGujGGEELU3HDjOy3lpM4eaPfril1IMTISyL0-O0Skhv7HtihZ7=w2560-h1440-rw";

class App extends React.Component {

  projectPreview(title, description, backgroundImageSrc, ...iconSrcs) {

    let iconElements = [];

    for (let iconSrc of iconSrcs) {

      iconElements.push(<img
        alt=""
        className="project-preview-icon"
        src={iconSrc} />)

    }

    return (<>
      <h1 className="project-preview-title">
        {title}
      </h1>

      <div
        className="project-preview-content-container"
        style={{
          // backgroundImage: "url(\"./images/destiny_1.png\")",
        }}
      >
        {/* <img
          className="project-preview-image"
          src={backgroundImageSrc}
        /> */}
        <div
          className="project-preview-image-overlay"
        >
          <div
            className="project-preview-description"
          >
            {description}
          </div>
          {/* <div
            className="project-preview-icon-container"
          >{iconElements}</div> */}
        </div>
      </div>



    </>);
  }

  testContent() {
    return <>
      <h1
        className="project-title"
      >
        Project Name
      </h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        <br />
        <br />
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>

      <img
        alt=""
        style={{ width: '100%' }}
        src={"https://cdn.britannica.com/39/7139-050-A88818BB/Himalayan-chocolate-point.jpg"}>
      </img>
    </>
  }


  solvableContent() {

    return <>

      <h1 className="project-title">Solvable</h1>
      <div
        className="project-details-container"
      >
        <div
          className="project-details"
        >

          <div
            className="project-brief-container"
          >
            <ul>
              <li>Custom computer algebra system with step-by-step equation solving</li>
              <li>Custom declarative, interpreted mathematical scripting language</li>
              <li>Custom native Android UI elements and expression rendering</li>
              <li>Significant use of software testing</li>
              <li>Over 10,000 downloads on Google Play</li>
            </ul>
          </div>
          <p>
            Solvable was my first try at making a tangible product from an idea.
            As an engineering student, I spent a lot of time doing basic algebra
            and punching formulas into my graphing calculator when working on assignments.
            I wanted to be able to enter a formula once, and substitute new values for
            varaibles when needed, letting the computer do the rest.
            <br />
            <br />
            The project started as a simple expression parser and evaluator, and over two years, it
            grew into an entirely custom computer algebra system with step-by-step equation solving
            and other features. It is powered by a purpose-built, declarative, interpreted mathematical scripting
            language that allows new functionality to be added without writing any real code.
            <br />
            <br />
            I ended up writing a suite of tools to make working on my codebase easier, including a simple Visual Studio
            Code syntax highlighter for my scripting language, a basic interpreter for converting the scripts into
            data usable by the computer algebra system, and a visual debugger for the most complicated algorithm
            in the math engine.
            <br />
            <br />
            The final result of this project is a free Android app with guided equation solving, graphing, and other
            features.
          </p>

          <div
            className="project-links-container"
          >
            <a
              className="button"
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.schramkowski.mathsolver"
            >Google Play</a>
            <a
              className="button"
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=qPX-jGhnYhQ&list=PLq-KElBiS4tDLl6C4k0zf7-j5NhTZVNGg&index=12"
            >YouTube</a>
          </div>

        </div>

        <MultiImage
          className="project-images-container"
          images={[
            "https://play-lh.googleusercontent.com/wG5xoJHgCD5NxjZ8RwGujGGEELU3HDjOy3lpM4eaPfril1IMTISyL0-O0Skhv7HtihZ7=w2560-h1440-rw",
            "https://play-lh.googleusercontent.com/pPCVDws-U_1SsfCf9oAqO-Ee-JckbDgYde54afcIViJgStQcXbDECIZxF0kY9AR2dpBP=w2560-h1440-rw",
            "https://play-lh.googleusercontent.com/mzTQY27vi8S3nP8LQj55FunsNWzLghXDhvCEDjrMarEruHSJGHE5fUOm-cbH-9qKJ5g=w2560-h1440-rw"
          ]}

        />

      </div>

    </>

  }

  robotPathPlannerContent() {

    return <>

      <h1 className="project-title">Robot Path Planner</h1>
      <div
        className="project-details-container"
      >
        <div
          className="project-details"
        >

          <div
            className="project-brief-container"
          >
            <ul>
              <li>Java code generation from graphical interface</li>
              <li>Linear and radial path segments with automatic radius calculation</li>
              <li>Save and load paths from local files</li>
              <li>Educational collaboration with high school students</li>
              {/* <li>Pure HTML and Javascript - no framework used</li> */}
            </ul>
          </div>
          <p>
            This project was built during my time as a high school robotics mentor for the 2020 FIRST Robotics Competition season.
            The goal was to make it easier for students to program paths for the robot to follow during its autonomous routine.
            Before this project was created, each path had to be hard-coded, which was both time consuming and inaccessible to
            students without programming experience.
            <br />
            <br />
            I helped the students develop a codebase to allow the robot to interpret the generated paths, which was used successfully
            during the competition season. Due to the physical attributes of the robot, spline-based paths were ruled out early on, so
            I implemented this app to generate only linear segments with optional turn radii between segments. Each radius can be set
            individually or automatically.
            <br />
            <br />
            The outcome of this project was a simple but effective graphical alternative to hard-coding paths, which allowed the
            team to rapidly iterate and improve the performance. It was also accessible enough to allow multiple girl scouts aged
            10 or younger to generate paths and watch the robot follow them.
          </p>

          <div
            className="project-links-container"
          >
            <a
              className="button"
              target="_blank"
              rel="noreferrer"
              href="https://path-planner.web.app/"
            >Visit</a>

          </div>

        </div>

        <MultiImage
          className="project-images-container"
          images={[
            pathPlannerImage1,
            pathPlannerImage2,
          ]}

        />

      </div>

    </>

  }

  destinySecondScreenContent() {

    return <>

      <h1 className="project-title">Destiny Second Screen</h1>
      <div
        className="project-details-container"
      >
        <div
          className="project-details"
        >

          <div
            className="project-brief-container"
          >
            <ul>
              <li>Core Destiny UI elements carefully translated from game to web</li>
              <li>Extrapolation of Destiny UI design language used for most added features</li>
              <li>Interaction with actual game using Bungie API</li>
              <li>Use of OAuth and local browser storage</li>
            </ul>
          </div>
          <p>
            Destiny Second Screen enhances the Destiny 2 experience for players who
            have multiple monitors.
            <br />
            <br />
            The app has features the game lacks, including loadouts, drag and drop item transfer, and
            additional icons and stats on items, allowing the player to spend less time on inventory management
            and more time playing.
            <br />
            <br />
            Destiny Second Screen strictly follows Destiny's award winning UI design language where possible,
            including layout, navigation, and even animations.
            <br />
            <br />
            <br />
            <br />
            Note: A Destiny account is required to use the website.
          </p>

          <div
            className="project-links-container"
          >
            <a
              className="button"
              target="_blank"
              rel="noreferrer"
              href="https://destinysecondscreen.com/"
            >Visit</a>

            <a
              className="button"
              target="_blank"
              rel="noreferrer"
              href="https://youtu.be/SDNAHQh-CtM"
            >YouTube</a>

          </div>

        </div>

        <MultiImage
          className="project-images-container"
          images={[
            destinyImage1,
            destinyImage2,
            destinyImage3,
          ]}

        />

      </div>

    </>

  }

  vrWingsuitSimluatorContent() {

    return <>

      <h1 className="project-title">VR Wingsuit Simulator</h1>
      <div
        className="project-details-container"
      >
        <div
          className="project-details"
        >

          <div
            className="project-brief-container"
          >
            <ul>
              <li>Built with Unreal Engine 4 for Meta Quest 2</li>
              <li>Physics-based body control</li>
              <li>3D heads-up display with speed and other info</li>
              <li>Use of heightmap data and complex landscape materials</li>
            </ul>
          </div>
          <p>
            The wingsuit simulator was a team project done for CS579: Virtual Reality at UW-Madison.
            My project partner, Jeff Kim, was a fan of extreme sports, so we decided to recreate the exhilarating
            experience of flying with a wingsuit for our project.
            <br />
            <br />
            Our goal was to map the full-body control of real wingsuit flying onto the more limited motion
            controller and headset control of the Meta Quest 2. We also wanted to make the user truly feel
            a sense of motion while using the simulator.
            <br />
            <br />
            We implemented a physics-based control system with simulated air drag as well as a control
            scheme using the player's arms to maneuver. This system allowed for the feeling of flight while
            also allowing us to tweak the physics to a degree that felt approachable and did not disorient
            the player. We used heightmap data from the Grand Canyon to create our game world, and we added a flight course
            and stunt system to give the player objectives outside of free roam.
            <br />
            <br />
            The result of this project was a fun and immersive wingsuit flying experience where the player
            could closely replicate the sensation of flying in a VR environment.
          </p>

          <div
            className="project-links-container"
          >
            <a
              className="button"
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=u7oAqu-z8cY"
            >YouTube</a>

          </div>

        </div>

        <MultiImage
          className="project-images-container"
          images={[
            wingsuitImage1,
            wingsuitImage2,
          ]}

        />

      </div>

    </>

  }

  tryppiContent() {

    return <>

      <h1 className="project-title">Tryppi</h1>
      <div
        className="project-details-container"
      >
        <div
          className="project-details"
        >

          <div
            className="project-brief-container"
          >
            <ul>
              <li>Travel information displayed in simple and mobile-friendly format</li>
              <li>Different information displayed based on date</li>
              <li>Integration with Goggle Sheets for easy planning</li>
              <li>Links to Google Maps destinations for quick navigation</li>
            </ul>
          </div>
          <p>
            Tryppi is a quick website I created to use for a road trip I took recently.
            It displays all the crucial information for lodging and navigation at each stop
            in an elegant way, while also making it easy to get directions to each hotel.
            <br />
            <br />
            The website itself uses Javascript to update the "next stop" display based on the date,
            which keeps the user informed and aids in finding the directions to the hotel on the page.
            Additionally, there is a calendar on the page with the destinations labeled for each day,
            which is useful for looking ahead at a glance.
            <br />
            <br />
            The content on the page is not static, but rather it can be easily changed using Google Sheets,
            a tool I was already using to plan the trip itself. Updating the spreadsheet causes the website
            to update without the end user needing any knowledge of web design.
            <br />
            <br />
            If I were to spend more time on the project, I would add a login system and a way for individual users
            to plan their own trips.
            <br />
            <br />
            <br />
            <br />
            Note: As the trip is over, I have set a static date for the website for display purposes.
          </p>

          <div
            className="project-links-container"
          >
            <a
              className="button"
              target="_blank"
              rel="noreferrer"
              href="https://tryppi.web.app/"
            >Visit</a>

          </div>

        </div>

        <MultiImage
          className="project-images-container"
          images={[
            tryppiImage1,
            tryppiImage2,
          ]}

        />

      </div>

    </>

  }

  fpgaContent() {

    return <>

      <h1 className="project-title">FPGA Music Effects Accelerator</h1>
      <div
        className="project-details-container"
      >
        <div
          className="project-details"
          style={{ maxWidth: "800px", margin: "0 auto" }}
        >

          <div
            className="project-brief-container"
          >
            <ul>
              <li>Fully custom CPU and accelerator module</li>
              <li>Equalizer, tremolo, pitch shift, and other effects</li>
              <li>Purpose-built instruction set with macro-ops</li>
              <li>Intel Devcloud and remote computing used extensively</li>
              <li>Web-based graphical compiler and Java assembler</li>
            </ul>
          </div>
          <p>
            This project was a team project done for ECE554: Digital Engineering Laboratory at UW-Madison.
            One of my group members is a musician, so we decided to implement a digital guitar amplifier
            for our project.
            <br />
            <br />
            Our design was built to run on the FPGAs hosted by Intel Devcloud. The major constraint on our
            project was that it had to be some form of a hardware accelerator, meaning it needed to be able to
            recieve commands from a host CPU and process data independently.
            <br />
            <br />
            We created a custom set of instructions for our design to operate on, which could be used to set
            the parameters of each of the effects as well as start computations. The accelerator utilized Direct Memory Access to share data with
            the host CPU, meaning the host CPU could simply load the input data into memory and then signal the accelerator to start.
            <br />
            <br />
            To generate binaries in our instruction set, we created a simple web-based app that allowed a user to graphically
            change equalizer values and change effect parameters, which would then output a file in our assembly language. We also
            wrote an assembler in Java to output the final binary. Additionally, we wrote a variety of smaller tools to convert
            waveform (.WAV) files into raw data for our accelerator and vice versa.
            <br />
            <br />
            The result of our project was a design capable of processing an input sound or song and add effects to it. You
            can read more about this project and find demos in the report below.
          </p>

          <div
            className="project-links-container"
          >
            <a
              className="button"
              target="_blank"
              rel="noreferrer"
              href="https://drive.google.com/file/d/1KzzyjtD78FSkqGNHWuO6l_GNxu5lnmq0/view"
            >Report</a>

          </div>

        </div>

      </div>

    </>

  }

  render() {
    return <>

      <div
        className="nav-bar-container"
      >
        <div
          className="nav-bar-content-container"
        >
          <div
            className="nav-bar-title"
          >Jacob Schramkowski</div>
          <div
            className="nav-bar-items-container"
          >
            <a href="#about">About</a>
            <a href="#projects">Projects</a>
            <a href="#contact">Contact</a>

          </div>
        </div>
      </div>

      <div className="main">



        <div
          className="main-content-container"
        >

          <h1 className="name-plate">
            Jacob Schramkowski
          </h1>

          <div

            className="section-container"
          >
            <h1
              id="about"
              className="section-title">About</h1>
            <p
              className="about"
            >
              Hi! I'm Jacob. I recently graduated from the University of Wisconsin - Madison
              with a degree in Computer Engineering and Computer Science.
              I have experience ranging from FPGA programming
              and microcontroller firmware to web, app, and game development in both solo and collaborative environments.
              {/* <br />
              <br />
              In my free time, I enjoy working on hobby projects, such as web and mobile apps.
              More recently, I have been learning indie game development using Blender 3D and Unreal Engine.
              I'm excited to start a career where I can continue to develop my skills! */}
            </p>
          </div>

          <div
            className="section-container"
          >
            <h1
              id="projects"
              className="section-title">Projects</h1>

            <div className="test-grid">

              <Project
                className="project-preview-container"
                previewContent={this.projectPreview(
                  "Solvable",
                  "A calculator app with guided equation solving, graphing, and more",
                  solvableImage1,
                  androidIcon
                )}
                expandedContent={<div className="project-content">

                  {this.solvableContent()}

                </div>}
              />

              <Project
                className="project-preview-container"
                previewContent={this.projectPreview(
                  "Destiny Second Screen",
                  "A web companion app for Bungie's sci-fi adventure game, Destiny 2",
                  destinyImage1,
                  javascriptIcon
                )}
                expandedContent={<div className="project-content">

                  {this.destinySecondScreenContent()}

                </div>}
              />


              <Project
                className="project-preview-container"
                previewContent={this.projectPreview(
                  "VR Wingsuit Simulator",
                  "An immersive wingsuit flying experience in virtual reality",
                  wingsuitImage1,
                  chipIcon
                )}
                expandedContent={<div className="project-content">

                  {this.vrWingsuitSimluatorContent()}

                </div>}
              />

              <Project
                className="project-preview-container"
                previewContent={this.projectPreview("Robot Path Planner", "A web app for creating paths for FIRST Robotics robots", htmlIcon, javascriptIcon)}
                expandedContent={<div className="project-content">

                  {this.robotPathPlannerContent()}

                </div>}
              />




              <Project
                className="project-preview-container"
                previewContent={this.projectPreview("Tryppi", "A simple and elegant website for organizing road trip destinations", androidIcon, javascriptIcon, chipIcon)}
                expandedContent={<div className="project-content">

                  {this.tryppiContent()}

                </div>}
              />

              <Project
                className="project-preview-container"
                previewContent={this.projectPreview("FPGA Music Effects Accelerator", "A digital guitar amp implemented in Verilog", androidIcon, javascriptIcon, chipIcon)}
                expandedContent={<div className="project-content">

                  {this.fpgaContent()}

                </div>}
              />

            </div>

          </div>


          <div
            className="section-container"
          >
            <h1
              id="contact"
              className="section-title">Contact</h1>
            <a
              className="button"
              href="https://www.linkedin.com/in/schramkowski"
              target="_blank"
              rel="noreferrer"
            >LinkedIn</a>

          </div>



        </div>

      </div>
    </>;
  }
}

export default App;
